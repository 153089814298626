@import url('https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@300;400;500;600;700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
}

video {
    max-width: 100%;
    height: auto;
    display: block;
}

header {
    height: 400px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.744)), url('../public/img/fishes.JPG');    
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;
}

#root {
    font-family: "Hind Madurai", sans-serif;
    color: #f2e5e5;
    line-height: 1.5;
    background-color: #050919;
}

h1, h2, h3, h4, h5, a {
    font-family: "Montserrat", sans-serif;
}

.container-styled {
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
    text-align: center;
    color: #f2e5e5;
    background-color: #050919; 
    padding: 10px 50px;
    font-weight: 700;
    font-size: 24px; 
}

.services-list ol {
    counter-reset: item;
    padding-left: 0;
}
.icon-color{
    color: #d3d3d3;
}
.center-text {
    text-align: center;
  }
  
.services-list li {
    list-style: none;
    margin: 20px 0;
    padding: 20px 15px 20px 0;
    border: 1px solid #d3d3d3;
    border-radius: 10px;
    position: relative;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    color: #d3d3d3;
    font-size: 16px; 
}

.services-list li:before {
    content: counter(item);
    counter-increment: item;
    position: absolute;
    top: -10px;
    left: -10px;
    background: #D3D3D3;
    color: #050919;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
}

.icon {
    margin-right: 10px;
    vertical-align: middle;
}

.content {
    margin-left: 50px;
}

.content h2 {
    margin-top: 0;
    color: #01579b;
    font-size: 20px; 
}

.paragraph {
    margin: 25px;
    text-align: justify;
    font-size: 16px; 
}

.paragraph span {
    font-size: larger;
    font-weight: 500;
}

.carousel {
    margin: auto;
}

.carousel-inner img {
    max-height: 300px; 
    width: 100%;
    object-fit: cover;
}
.carousel-caption {
    background-color: #050919;
}

footer {
    padding: 40px 0;
    margin-top: 5rem;
    background-color: #1f222d;
}

footer h3 {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 16px; 
}

footer ul {
    padding: 25px;
}

footer ul li {
    margin-bottom: 10px;
    font-size: 14px; 
    list-style-type: none;
}

footer i {
    padding-right: 20px;
}

footer p {
    margin-top: 20px;
    font-size: 16px; 
}

@media (min-width: 600px) {
    h1 {
        font-size: 26px;
    }

    .services-list li {
        font-size: 18px;
    }

    .content h2 {
        font-size: 22px;
    }

    .paragraph {
        font-size: 18px;
    }

    footer h3 {
        font-size: 18px;
    }

    footer ul li {
        font-size: 16px;
    }

    footer p {
        font-size: 18px;
    }
}

@media (min-width: 768px) {
    h1 {
        font-size: 28px;
    }

    .services-list li {
        font-size: 20px;
    }

    .content h2 {
        font-size: 24px;
    }

    .paragraph {
        font-size: 20px;
    }

    footer h3 {
        font-size: 20px;
    }

    footer ul li {
        font-size: 18px;
    }

    footer p {
        font-size: 20px;
    }
}

@media (min-width: 1024px) {
    h1 {
        font-size: 30px;
    }

    .services-list li {
        font-size: 20px;
    }

    .content h2 {
        font-size: 24px;
    }

    .paragraph {
        font-size: 20px;
    }

    footer h3 {
        font-size: 20px;
    }

    footer ul li {
        font-size: 18px;
    }

    footer p {
        font-size: 20px;
    }
}



.custom-list {
    position: relative;
}

.custom-list h2 {
    color: #fff;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 10px;
}

.custom-list ul {
    position: relative;
}

.custom-list ul li {
    position: relative;
    left: 0;
    color: #fce4ec;
    list-style: none;
    margin: 4px 0;
    border-left: 2px solid #7F2108;
    transition: 0.5s;
    cursor: pointer;
}

.custom-list ul li:hover {
    left: 10px;
}

.custom-list ul li span {
    position: relative;
    padding: 8px;
    padding-left: 12px;
    display: inline-block;
    z-index: 1;
    transition: 0.5s;
}

.custom-list ul li:hover span {
    color: #111;
}

.custom-list ul li:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #7F2108;
    transform: scaleX(0);
    transform-origin: left;
    transition: 0.5s;
}

.custom-list ul li:hover:before {
    transform: scaleX(1);
}
.custom-list a{
    color: #D3D3D3;
    text-decoration: none;
}
